import { Button, Fab, Typography, Zoom } from "@mui/material";
import React, { useEffect, useState } from "react";
import GridList from "../../components/GridList/GridList.component";
import { useAppDispatch, useAppSelector } from "../../store/redux.hooks";
import {
  selectUserByIdForForm,
  selectUsers,
  setError,
} from "../../store/Users.slice";
import { GridCol } from "../../utils/types";
import { GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { LocationSchema } from "../../store/Location.slice";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";

import FormDialog from "../../components/FormDialog/FormDialog.component";
import UserForm from "../../components/UserForm/UserForm.component";
import { useLocationsHook } from "../../hooks/useLocations.hook";
import {
  User,
  userFormSchema,
  UserFormType,
  UserRoles,
  userSchema,
} from "../../components/Users/Users.types";
import {
  addUser,
  deleteUser,
  getUsers,
  updateUser,
} from "../../components/Users/Users.thunks";
import { toast } from "react-toastify";
const userFormDefaultValues: UserFormType = {
  teamName: "",
  role: "player",
  // phone_number: z.coerce.number().optional(), //???
  // admin: "",
  adminId: "",
  code: "",
  verificationCode: "",
  playingLocationSteps: [],
};
const UsersPage = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);

  const isLoading = useAppSelector((state) => state.users.isLoading);
  const errorMessage = useAppSelector((state) => state.users.error);
  const [selectedID, setSelectedID] = React.useState<LocationSchema["_id"]>("");
  const selectedUser = useAppSelector((state) => {
    return selectUserByIdForForm(state, selectedID);
  });
  console.log(selectedUser, "selectedUser");
  // const selectedUser = userFormSchema.parse(selected)
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<"Add" | "Edit">("Add");
  const form = useForm<UserFormType>({
    //values: selectedUser ? { ...selectedUser } : { ...userFormDefaultValues },
    defaultValues: { ...userFormDefaultValues },
    resolver: zodResolver(userFormSchema),
  });
  const { reset, handleSubmit } = form;
  const handleAddClick = () => {
    setSelectedID("");
    setIsOpen(true);
    setType("Add");
  };
  const handleEditClick = (id: GridRowId) => {
    setSelectedID(id as string); //change type
    setIsOpen(true);
    setType("Edit");
    dispatch(setError(""));
    reset();
  };
  const handleDeleteClick = (id: GridRowId) => {
    dispatch(deleteUser(id as string))
      .unwrap()
      .then(() => {
        toast.success("User deleted successfully");
      });
  };
  const onSubmit = (data: UserFormType) => {
    // console.log(data, "data");
    // const formData = new FormData();
    //
    // formData.set("name", data.name);
    //
    // formData.append("fileName", data.fileName[0]);
    // console.log(formData.values());
    if (type === "Add") {
      // if (data.playingLocationSteps && data.playingLocationSteps.length > 0) {
      //   data.playingLocationId = data.playingLocationSteps[0];
      // }
      // delete data.playingGameId;

      dispatch(addUser(data))
        .unwrap()
        .then(() => {
          toast.success("User added successfully");
        });
    } else {
      dispatch(updateUser({ ...data, _id: selectedID }))
        .unwrap()
        .then(() => {
          reset({ ...data });
          toast.success("User updated successfully");
        });
      //todo change
    }
  };
  useEffect(() => {
    // setSelectedUser(
    //   users.find((user) => user._id === selectedID) || userDefaultValues
    // );
    console.log(selectedID, "selectedID");
    // reset();
  }, [selectedID]);
  const { locationsVariants } = useLocationsHook();
  // console.log(locationsVariants, "locationsVariants");

  //format values of playingLocationSteps instead of ids, to show values from locationvariant
  const userColumns: GridCol<User>[] = [
    { field: "_id", headerName: "ID", width: 60 },
    { field: "code", headerName: "Code", width: 100 },
    { field: "verificationCode", headerName: "Verification Code", width: 100 },
    { field: "teamName", headerName: "Team name", width: 100 },
    { field: "role", headerName: "Role", width: 80 },
    // { field: "chatTo", headerName: "Chat id??", width: 80 },
    {
      field: "adminId",
      headerName: "Admin of user",
      width: 100,
      type: "singleSelect",
      valueOptions: users
        .filter((user) => user.role === UserRoles.enum.admin)
        .map((user) => ({
          value: user._id,
          label: user.teamName,
        })),
    },
    { field: "playStatus", headerName: "Playing status", width: 100 },
    {
      field: "playingLocationId",
      headerName: "Current location",
      width: 100,
      type: "singleSelect",
      valueOptions: locationsVariants,
    },
    {
      field: "playingLocationSteps",
      headerName: "Playing location steps",
      width: 300,
      valueOptions: locationsVariants,
      valueFormatter: ({ value }) => {
        return value?.map((id: string) => {
          const location = locationsVariants.find(
            (location) => location.value === id
          );
          return location?.label || id;
        });
      },
    },
  ];

  useEffect(() => {
    dispatch(getUsers);
  }, []);
  return (
    <>
      <Typography variant="h2" component="h1">
        Users
      </Typography>
      <GridList
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleAddClick={handleAddClick}
        columns={userColumns}
        rows={users}
      />
      <FormDialog
        title={`${type} User `}
        open={isOpen}
        isLoading={isLoading}
        actions={[
          <Button
            key={"1_SaveButton"}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Save
          </Button>,
        ]}
        handleClose={() => {
          reset(userFormDefaultValues);
          setIsOpen(false);
        }}
      >
        <>
          {errorMessage && (
            <Typography variant={"h5"} color={"red"}>
              {errorMessage}
            </Typography>
          )}
          <UserForm
            form={form}
            defaultValues={selectedUser}
            onSubmit={onSubmit}
            isDisabled={isLoading}
          />
        </>
      </FormDialog>
    </>
  );
};

export default UsersPage;
