import React from "react";
import LeaderBoard from "../../features/LeaderBoard/LeaderBoard";
import {
  Box,
  Container,
  CssBaseline,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { useAppSelector } from "../../store/redux.hooks";
import { selectAllLocations } from "../../store/Location.slice";
import { Link, useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LinkRouter from "../../utils/LinkRouter";
import { jamesonTheme } from "../../index";
const LeaderBoardPage = () => {
  const locations = useAppSelector(selectAllLocations);

  const locationID = useParams<{ id: string }>().id || locations[0]?._id;

  return (
    <>
      <ThemeProvider theme={jamesonTheme}>
        <CssBaseline enableColorScheme />
        <Box
          sx={{
            display: "flex",
            minHeight: "80vh",
            py: "20px",
            // my: "20px",
          }}
        >
          <Paper
            elevation={0}
            sx={{ p: "20px", margin: "auto", minHeight: "50vh" }}
          >
            {/*<>*/}
            {/*  <Tabs*/}
            {/*    aria-label="basic tabs example"*/}
            {/*    value={locationID || locations[1]?._id}*/}
            {/*  >*/}
            {/*    {locations.map((loc) => (*/}
            {/*      <Tab*/}
            {/*        key={loc._id}*/}
            {/*        label={loc.name}*/}
            {/*        value={loc._id}*/}
            {/*        href={"/leaderboard/" + loc._id}*/}
            {/*        component={LinkRouter}*/}
            {/*      />*/}
            {/*    ))}*/}
            {/*  </Tabs>*/}
            {/*  /!*{locations.map((loc) => (*!/*/}
            {/*  /!*  <Link to={"/leaderboard/" + loc._id} key={loc._id}>*!/*/}
            {/*  /!*    {loc.name}*!/*/}
            {/*  /!*  </Link>*!/*/}
            {/*  /!*))}*!/*/}
            {/*</>*/}
            <Container>
              <h1>{locations.find((loc) => loc._id === locationID)?.name}</h1>
              <LeaderBoard locationID={locationID} />
            </Container>
          </Paper>
        </Box>
      </ThemeProvider>
    </>
  );
};
export default LeaderBoardPage;
